<template>
  <MdsLayoutGrid class="advice-intro">
    <MdsRow align-horizontal="space-around" align-vertical="center">
      <h4
        v-html="
          $contentTransposer('landing.introductionTxt', {
            productName: $contentTransposer('advisorFirmInfo.productName')
          })
        "
        data-ee-test="advice-intro-content"
      />
    </MdsRow>
  </MdsLayoutGrid>
</template>

<script>
import { MdsLayoutGrid, MdsRow } from '@mds/layout-grid';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow
  }
};
</script>
