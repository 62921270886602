import configClient from './config';
import _ from 'lodash';
import store from '@/simpleStore';

export const mergeClientCustomizations = () => {
  // Note: use advisoryFirmId if any AMA plan in below const
  const { customizationId, version } = store.state;

  let content = {};
  // Note: This below code snippet is the example for client customizations.
  // switch (customizationId) {
  //   case 'ClientId':
  //     content = ClientId;
  //     break;
  // }

  // Override for Plan specific customizations
  switch (`${customizationId}.${version}`) {
    case 'INGG.VRA':
      content = configClient['INGG.VRA'];
      break;
  }

  // Note: This below code snippet is the example for AMA specific customizations.
  // switch (`${customizationId}.${advisoryFirmId}`) {
  //   case 'ClientId_AdvisoryFirmId':
  //     content = ClientId_AdvisoryFirmId;
  //     break;
  // }

  return _.merge({}, configClient.DEFAULT, content);
};
