//
// NOTE: Name convention as follows: advisoryFirmId use _ (underscore) and for Plan specific use .(Dot);
//

const DEFAULT = {
  showIdleModal: true,
  showExitModal: true
};

export default {
  DEFAULT,
  'INGG.VRA': { showIdleModal: false, showExitModal: false }
};
