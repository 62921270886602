import { merge } from 'lodash';

export default {
  SET_CONTENT(state, content) {
    state.content = content;
  },
  SET_FOOTER_LINKS_CONTENT(state, footerLinksContent) {
    state.content = merge({}, state.content, footerLinksContent);
  },
  SET_HERO_TEXT(state, text) {
    state.config.heroText = text;
  },
  SET_PROGRAM_FEE_STATEMENT(state, programFee) {
    state.programFee = programFee;
  },
  SET_SHOW_CONFIRM_ENROLLMENT_MODAL_FLAG(state, showConfirmEnrollmentModal) {
    state.showConfirmEnrollmentModal = showConfirmEnrollmentModal;
  },
  SET_SHOW_LOADER(state, shouldDisplay) {
    state.showLoader = shouldDisplay;
  },
  SET_ENV_CONFIG(state, configObj) {
    state.config.configObjCustomization = configObj.customization;
    state.config.env = configObj.env;
  },
  SET_CLIENT_CUSTOMIZATIONS(state, clientCustomizations) {
    state.clientCustomizations = clientCustomizations;
  },
  SET_SAVING_RECOMMENDATION_SHOW_LOADER(state, showLoader) {
    state.savingRecommendation.showLoader = showLoader;
  },
  SET_SAVING_RECOMMENDATION_PROPOSED_ADVICE(state, savingRateDetail) {
    state.savingRecommendation.savingRateDetail = savingRateDetail;
  },
  SET_IS_ENROLL_BUTTON_DISABLE(state, isEnrollButtonDisable) {
    state.isEnrollButtonDisable = isEnrollButtonDisable;
  },
  SET_PROPOSAL_ADVICE(state, proposalAdvice) {
    state.proposalAdvice = proposalAdvice;
  },
  SET_IS_INCOME_PROJECTION_DATA_AVAILABLE(
    state,
    incomeProjectionDataAvailable
  ) {
    state.incomeProjectionDataAvailable = incomeProjectionDataAvailable;
  },
  SET_AGES(state, ages) {
    state.ages = ages;
  },
  SET_RECAPTCHA_V2(state, recaptchaV2) {
    state.recaptchaV2 = recaptchaV2;
  },
  SET_SHOW_INGG_VRA_UX(state, showInggVraUX) {
    state.showInggVraUX = showInggVraUX;
  }
};
