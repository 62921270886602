import { CONTACT_US } from '../../../constants';
export default {
  landing: {
    introductionTxt: `<p>Welcome! As part of your employee benefits package, your employer has made {{productName}} available to you to help you get on track for your retirement goals. 
      {{productName}} is designed to provide you with a personalized retirement strategy and the option for professional, ongoing management of your retirement account.</p>
      <p>Before we get started, let’s confirm your information below with what we have on file from your employer so we can provide you with a sneak peek of your personalized retirement strategy.</p>`,
    verifyForm: {
      subHeader:
        'By confirming your information, we can provide you with a sneak peek of the personalized recommendations you’ll receive in {{productName}}.'
    }
  },
  advice: {
    disclaimer: `*This information is based on the age, account balance, current fund allocation, current
      contributions, and plan type provided by your plan provider. It may also consider brokerage
      accounts, retirement loans, gender and the state where you live, if we receive those inputs
      from your plan provider. If you have savings earmarked for retirement outside of your retirement
      plan account, such as a pension, social security benefits, spousal assets, or other retirement
      accounts, we encourage you to log into the {{productName}} website by going to your plan
      provider’s website so that you can provide us with details and we can further personalize our
      suggestions for you. We assume a current retirement age of 65. We believe holding your employer’s
      stock can increase your portfolio risk. If applicable, you direct us to sell off your non-restricted
      company stock according to our methodology when you click "Confirm Enrollment" on this page.`,
    fee: {
      feeNote:
        'This fee covers both the registered investment adviser’s fee and Morningstar Investment Management’s fee for this service.'
    }
  },
  footer: {
    disclaimersOfferedBy: `<p class="ee__body-text--s">Managed Accounts is offered by Morningstar Investment Management LLC and is intended for citizens or legal residents of the United States or its territories. 
    The portfolios available to you through Managed Accounts were created by {{advisorFirmName}}, an investment adviser (the “IA”) chosen by your employer. 
    Morningstar Investment Management LLC, a registered investment adviser and subsidiary of Morningstar, Inc., is responsible for choosing the portfolio for your retirement plan account from those created by the IA.
    The IA is not affiliated with Morningstar Investment Management and Morningstar Investment Management is not responsible for the portfolios the IA creates. 
    The IA is not responsible for the portfolio selection made by Morningstar Investment Management for you, nor for other recommendations made by Morningstar Investment Management through Managed Accounts. 
    Investment advice delivered by Morningstar Investment Management is based on information provided and limited to the investment options available in your retirement plan. 
    Projections and other information regarding the likelihood of various retirement income and/or investment outcomes are hypothetical in nature, do not reflect actual results, and are not guarantees of future results. Results may vary with each use and over time.</p>
    <p class="ee__body-text--s">All investments involve risk, including the loss of principal. There can be no assurance that any financial strategy will be successful. Morningstar Investment Management does not guarantee that the results of their advice, recommendations or objectives of a strategy will be achieved.</p>`,
    contact: {
      content:
        "{{planProvider}} has teamed up with a registered investment adviser to offer you this service. Let's get you connected with the right group based on what you need to know.",
      providerTitle: 'Retirement and Plan Support',
      providerDescription:
        '<strong>{{planProvider}}</strong> can answer questions about your retirement account, troubleshooting any technical issues, and general questions about the service.',
      riaTitle: 'Portfolio and Investment Advice',
      riaDescription:
        'Your plan’s <strong>registered investment advisor</strong> can answer questions related to the recommendations and advice you’ve received today.'
    },
    footerLinks: {
      privacyPolicy: {
        type: 'link'
      },
      firmBrochure: {
        name:
          'RIA and Morningstar Investment Management Firm Brochure and Brochure Supplement'
      },
      contactUs: {
        name: 'Support',
        type: 'modal',
        modal: CONTACT_US,
        title: 'Support',
        show: true
      }
    }
  },
  modals: {
    confirmEnrollment: {
      acknowledge: `I acknowledge that I have received the
        <a href="{{firmBrochureLink}}" target="_blank">{{firmBrochureName}}</a>,
        <a href="{{relationshipSummaryLink}}" target="_blank">{{relationshipSummaryName}}</a> and
        <a href="{{advisoryAgreementLink}}" target="_blank">{{advisoryAgreementName}}</a>, and
        I consent to those terms and conditions. Upon enrollment, MIM will instruct my provider
        to make any changes to my savings rate or investments if needed.`
    }
  },
  aboutMorningStar: {
    header: 'About Morningstar Investment Management LLC'
  },
  advisorFirmInfo: {
    productName: 'Managed Accounts',
    advisorFirmName: ''
  },
  enroll: {
    confirmationText: `{{productName}} is thrilled to help you get and stay on track for your retirement
      goals. The registered investment adviser chosen by your employer is managing the portfolios available
      to you through {{productName}} and Morningstar Investment Management will keep you in an appropriate
      portfolio as your situation changes.`
  }
};
