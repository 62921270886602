var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"verify-eligibility-form"},[_c('h3',{staticClass:"ee__section-heading"},[_vm._v(" "+_vm._s(_vm.$contentTransposer('landing.verifyForm.header'))+" ")]),_c('MdsForm',{attrs:{"size":"large"}},[_c('MdsFieldset',{attrs:{"legend":"Eligibility Form","hidden-legend":""}},[_c('h5',{staticClass:"sub-heading",domProps:{"innerHTML":_vm._s(
          _vm.$contentTransposer('landing.verifyForm.subHeader', {
            productName: _vm.getProductName
          })
        )}}),_c('MdsRequiredFieldKey',{attrs:{"text":_vm.$contentTransposer('landing.verifyForm.requiredField')}}),_vm._l((_vm.getFields.filter(function (i) { return i.fieldType === 'text'; })),function(field){return _c('MdsInput',{key:field.id,attrs:{"id":field.id,"placeholder":field.placeholder,"label":field.label,"microcopy-above":field.microcopyTxt,"error-text":[("" + (field.errorMsg))],"error":field.errorStatus,"data-ee-test":("eligibility-form-" + (field.id)),"disabled":_vm.getFieldDisableStatus(field.id),"maxlength":field.maxlength,"size":"large","required":""},on:{"input":function($event){return _vm.checkIfEmpty(field.id)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})}),_vm._l((_vm.getFields.filter(function (i) { return i.fieldType === 'date'; })),function(field){return _c('MdsDatePicker',{key:field.id,attrs:{"min-max-dates":field.minMaxDates,"id":field.id,"value":field.value,"placeholder":field.placeholder,"label":field.label,"error-text":[("" + (field.errorMsg))],"error":field.errorStatus,"data-ee-test":("eligibility-form-" + (field.id)),"disabled":_vm.getFieldDisableStatus(field.id),"maxlength":field.maxlength,"typing":true,"date-format":{
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        },"size":"large","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function (value) { return _vm.handleInputChangedEvent(value, field.id); }}})})],2),_c('div',[_c('GRecaptcha',{ref:"GRecaptcha",staticClass:"gRecaptcha",attrs:{"getRecaptchaV2":_vm.getRecaptchaV2,"action-name":"ProposalVerify"},on:{"verify":_vm.onSubmit}})],1),_c('MdsButton',{staticClass:"verify-btn",attrs:{"disabled":_vm.getHasEmptyInputFields,"variation":"primary","size":"touch","data-ee-test":"eligibility-form-button","type":"button"},on:{"click":_vm.executeRecaptcha}},[_vm._v(" "+_vm._s(_vm.$contentTransposer('button.getAdvice'))+" ")])],1),_c('p',{staticClass:"link-txt"},[_c('span',[_vm._v(_vm._s(_vm.$contentTransposer('landing.verifyForm.privacyPolicy.text')))]),(_vm.getIsAMA)?_c('a',{staticClass:"ee-link",attrs:{"href":_vm.getDocumentLink(
          _vm.$contentTransposer('footer.footerLinks.privacyPolicy.url')
        ),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$contentTransposer('landing.verifyForm.privacyPolicy.link'))+".")]):_vm._e(),(!_vm.getIsAMA && _vm.getVersion)?_c('a',{staticClass:"ee-link",attrs:{"href":_vm.$contentTransposer('landing.verifyForm.privacyPolicy.url'),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.$contentTransposer('landing.verifyForm.privacyPolicy.link'))+".")]):_vm._e(),(!_vm.getIsAMA && !_vm.getVersion)?_c('span',{staticClass:"ee-link",on:{"click":function($event){return _vm.getContent(_vm.PRIVACY_POLICY)}}},[_vm._v(" "+_vm._s(_vm.$contentTransposer('landing.verifyForm.privacyPolicy.link'))+".")]):_vm._e()]),(_vm.showModal)?_c('ClientContentModal',{attrs:{"model":_vm.showModal,"content":_vm.modalData},on:{"footer-modal-dismissed":function($event){_vm.showModal = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }