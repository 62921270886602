import pageContent from '../app-language/page-content';
import modalContent from '../app-language/modal-content';
import { merge, isArray, uniq, mergeWith } from 'lodash';
import { CLIENTS, CLIENTS_WITH_AMA_CONFIG } from '../constants';

/*
  In case when the default translation contains an object with an array, and if we try to override the array content with lodash merge, 
  the array merge at times merges the default array content with the overridden array content instead of taking only the overridden array content.
  This customizer will make sure that the overridden array content is taken as is and not merged with the default array content.
*/
function customizer(objValue, srcValue) {
  return isArray(objValue) ? uniq(srcValue, objValue) : undefined;
}

export default {
  setContent(context, payLoad) {
    const { isAMA, advisoryFirmId, clientId, version } = payLoad;
    const defaultContent = pageContent.defaultPageContent;
    const clientsWithAMAConfig = CLIENTS_WITH_AMA_CONFIG.includes(clientId)
      ? pageContent[clientId]
      : {};
    const defaultAMAContent = isAMA ? pageContent.amaContent.main : {};
    const riaContent = pageContent[advisoryFirmId] || {};
    const clientContentNonAMA =
      !isAMA && clientId && CLIENTS.includes(clientId)
        ? pageContent[clientId]
        : {};
    const clientContentVersion =
      clientId && CLIENTS.includes(clientId) && version
        ? pageContent[`${clientId}_${version}`]
        : {};

    const clientAMAContentOverride =
      (isAMA &&
        CLIENTS_WITH_AMA_CONFIG.includes(clientId) &&
        pageContent.amaContent[clientId]) ||
      {};

    const content = mergeWith(
      {},
      defaultContent,
      clientsWithAMAConfig,
      defaultAMAContent,
      riaContent,
      clientContentNonAMA,
      clientContentVersion,
      clientAMAContentOverride,
      customizer
    );
    context.commit('SET_CONTENT', content);
  },

  setFooterLinksContent(context, payLoad) {
    const { isAMA, clientId, advisoryFirmId, version } = payLoad;
    const footerLinksContent = merge(
      {},
      modalContent.defaultModalContent,
      isAMA ? modalContent[advisoryFirmId] : {},
      !isAMA && clientId && CLIENTS.includes(clientId)
        ? modalContent[clientId]
        : {},
      clientId && CLIENTS.includes(clientId) && version
        ? modalContent[`${clientId}_${version}`]
        : {}
    );
    context.commit('SET_FOOTER_LINKS_CONTENT', footerLinksContent);
  },

  setHeroText(context, text) {
    context.commit('SET_HERO_TEXT', text);
  },

  setProgramFeeStatement(context, programFee) {
    context.commit('SET_PROGRAM_FEE_STATEMENT', programFee);
  },

  setShowConfirmEnrollmentModalFlag(context, showConfirmEnrollmentModal) {
    context.commit(
      'SET_SHOW_CONFIRM_ENROLLMENT_MODAL_FLAG',
      showConfirmEnrollmentModal
    );
  },

  setShowLoader(context, shouldDisplay) {
    context.commit('SET_SHOW_LOADER', shouldDisplay);
  },

  setEnvConfig(context, payLoad) {
    const { customization, env } = payLoad;
    context.commit('SET_ENV_CONFIG', { customization, env });
  },

  setClientCustomizations(context, payLoad) {
    context.commit('SET_CLIENT_CUSTOMIZATIONS', payLoad);
  },

  initializeClientCustomizations(context, payLoad) {
    context.dispatch('setClientCustomizations', payLoad);
  },

  initializeVueState(context, payLoad) {
    const { env } = payLoad;
    context.dispatch('setEnvConfig', { env });
    context.dispatch('setContent', payLoad);
    context.dispatch('setFooterLinksContent', payLoad);
    context.dispatch('setShowInggVraUX', payLoad);
  },

  setSavingRecommendationShowLoader(context, shouldDisplay) {
    context.commit('SET_SAVING_RECOMMENDATION_SHOW_LOADER', shouldDisplay);
  },

  setSavingRecommendationpSavingRateDetail(context, proposedAdvice) {
    context.commit('SET_SAVING_RECOMMENDATION_PROPOSED_ADVICE', proposedAdvice);
  },

  setIsEnrollButtonDisable(context, isEnrollButtonDisable) {
    context.commit('SET_IS_ENROLL_BUTTON_DISABLE', isEnrollButtonDisable);
  },

  setProposalAdvice(context, proposalAdvice) {
    context.commit('SET_PROPOSAL_ADVICE', proposalAdvice);
  },

  setIsIncomeProjectionDataAvailable(context, incomeProjectionDataAvailable) {
    context.commit(
      'SET_IS_INCOME_PROJECTION_DATA_AVAILABLE',
      incomeProjectionDataAvailable
    );
  },

  setAges(context, ages) {
    ages.sort((a, b) => {
      return a.age - b.age;
    });
    context.commit('SET_AGES', JSON.stringify(ages));
  },

  setIncomeProjection(context, proposalAdvice) {
    try {
      const retirementAges = proposalAdvice.RetirementAtAges;
      const ages = Object.keys(retirementAges).map(key => ({
        age: +key,
        value: Math.round(retirementAges[key])
      }));
      if (ages.length === 0) {
        context.dispatch('setIsIncomeProjectionDataAvailable', false);
      } else {
        context.dispatch('setIsIncomeProjectionDataAvailable', true);
        context.dispatch('setAges', ages);
      }
    } catch (error) {
      context.dispatch('setIsIncomeProjectionDataAvailable', false);
    }
  },

  setRecaptchaV2(context, recaptchaV2) {
    context.commit('SET_RECAPTCHA_V2', recaptchaV2);
  },

  setShowInggVraUX(context, payLoad) {
    const { clientId, version } = payLoad;
    const isInggVra = !!(
      clientId &&
      version &&
      clientId.toLowerCase() === 'ingg' &&
      version.toLowerCase() === 'vra'
    );
    context.commit('SET_SHOW_INGG_VRA_UX', isInggVra);
  }
};
